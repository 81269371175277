import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import React from "react";
import i18n from "../../core/i18n";

// eslint-disable-next-line react/prop-types
const CarCategoryDescriptionToolTip = ({ carCategory }) => {
    const title = i18n.exists(carCategory.descriptionWithDictionaryPrefix)
        ? i18n.t(carCategory.descriptionWithDictionaryPrefix)
        : carCategory.description;

    if (!title) {
        return null;
    }

    return (
        <Popover overlayClassName="order-form__popover" content={title} title={false}>
            <InfoCircleOutlined style={{ color: "#005093", marginInlineStart: 4, fontSize: 13 }} />
        </Popover>
    );
};

export default CarCategoryDescriptionToolTip;
