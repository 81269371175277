import React, { Component } from "react";
import { Select, Popover } from "antd";
import { observer } from "mobx-react";
import getTotalPrice from "../../../utils/newOrderStore/getTotalPrice";
import NewOrderStore from "../../../stores/Order/NewOrderStore";
import CurrencyStore from "../../../stores/Currency/CurrencyStore";
import getAmountInForeignCurrency from "../../../utils/newOrderStore/getAmountInForeignCurrency";
import PaymentStore from "../../../stores/Payment/PaymentStore";
import PayAllModalStore from "../../../stores/Payment/PayAllModalStore";
import HelpButton from "../../HelpButton";
import i18n from "../../../core/i18n";

class AllowedCurrencies extends Component {

    render() {
        const { item, paymentStatus } = this.props;
        if (
            (NewOrderStore.haveToPayInAdvance.thereRoute || NewOrderStore.haveToPayInAdvance.backRoute) &&
            (!item.inAdvance || (item.id === PaymentStore.TYPE_BILLING && item.inAdvance))
        ) {
            return (
                <div className="order-form__payment-help">
                    <Popover content={i18n.t("Tato metoda není určena pro platbu předem")} title={false}>
                        <HelpButton />
                    </Popover>
                </div>
            );
        }
        const { totalPrice } = getTotalPrice();

        return (
            <Select
                disabled={false}
                value={item.selectedCurrencyToPay}
                style={{ width: "170px" }}
                onChange={(value) => {
                    item.selectedCurrencyToPay = value;
                    CurrencyStore.selectCurrency(value);
                    localStorage.setItem("CurrencyStoreCode", value);
                }}
            >
                {item.selectedCurrencies.map((currencyTemp) => (
                    <Select.Option value={currencyTemp.code} key={currencyTemp.code}>
                        {getAmountInForeignCurrency(
                            PayAllModalStore.isModalOpened ? PayAllModalStore.totalPaymentPrice : totalPrice,
                            currencyTemp.code
                        ) || currencyTemp.code}
                    </Select.Option>
                ))}
            </Select>
        );
    }
}

export default observer(AllowedCurrencies);
